<template>
	<LinkWrap
		v-bind="link"
		@mouseenter="preloadVariants"
		ondragstart="return false"
		draggable="false"
	>
		<TnCard
			borderRadius="l"
			class="card"
		>
			<!-- Badge -->
			<template
				v-if="label"
				#badge
			>
				<TnBadge
					:size="isMobile ? '2xs' : 's'"
					compact
					:category="badgeCategory"
					class="badge-container"
					>{{ label }}</TnBadge
				>
			</template>
			<!-- Badge -->

			<!-- Image -->
			<div
				class="image-container"
				v-if="loading"
			>
				<TnSkeleton
					height="100%"
					width="100%"
					border-radius="m"
				/>
			</div>
			<div
				v-if="!loading"
				class="image-container"
				@click="buttonVariant && $emit('show-modal')"
			>
				<template v-if="productVariants.length === 0 && image">
					<NuxtImg
						:src="image.md"
						:modifiers="{ trim: 10 }"
						:height="isMobile ? 100 : 200"
						width="auto"
					/>
				</template>
				<template v-else-if="productVariants.length > 0">
					<NuxtImg
						v-for="variant in productVariants"
						:alt="variant.image.alt"
						:src="variant.image.md"
						:loading="variantsPreloaded ? 'eager' : 'lazy'"
						v-show="variant.id === safeCurrentVariant"
						:key="variant.id"
						:modifiers="{ trim: 10 }"
						:height="isMobile ? 100 : 200"
						width="auto"
					/>
				</template>
			</div>
			<!-- Image -->

			<div class="card-content">
				<!-- Heading -->

				<div class="heading">
					<!-- <div class="brand-color"> -->
					<p
						v-if="!loading"
						class="product-brand"
					>
						{{ brand }}
					</p>
					<!-- Color variant -->
					<div class="title-variant-wrapper">
						<ul
							class="variant-selector"
							v-if="!hideVariant && !loading"
						>
							<li
								class="variant"
								:class="{ active: currentVariant === variant.id }"
								@mouseover="changeVariant(variant)"
								v-for="variant in productVariants"
								:style="`background-color: ${variant.colorCode || variant.colorcode}`"
								:title="variant.color"
								:key="variant.id"
							></li>
							<li
								v-show="moreVariants"
								class="variant more"
							>
								+{{ moreVariants }}
							</li>
						</ul>
					</div>
					<!-- Color variant -->
					<!-- </div> -->
					<TnSkeleton
						height="16px"
						v-if="loading"
						:width="isMobile ? '45%' : '80%'"
						class="product-brand margin-bottom-m"
						border-radius="m"
					/>

					<p
						v-if="!loading"
						class="product-title"
					>
						{{ title }}
					</p>
					<TnSkeleton
						height="20px"
						v-if="loading"
						width="80%"
						class="product-title margin-bottom-xs"
						border-radius="m"
					/>

					<!-- TODO:- Don't show model until discussed with designer -->
					<p
						class="product-model"
						v-if="!loading && useModelNameCategory && false"
					>
						{{ model === "Model" ? "" : model }}
					</p>
				</div>
				<!-- Heading -->

				<!-- Price -->

				<div
					v-for="(tab, index) in tabs"
					:key="index"
					class="price-container"
					v-show="activeTab(index)"
				>
					<div>
						<div
							class="price"
							v-if="loading"
						>
							<div class="tab-price">
								<TnSkeleton
									:height="isMobile ? '21' : '31'"
									width="50%"
									border-radius="m"
									style="margin-bottom: 5px"
								/>
							</div>
							<div style="display: flex; flex-direction: column">
								<TnSkeleton
									height="12"
									width="65%"
									border-radius="m"
									style="margin-bottom: 5px"
								/>
								<TnSkeleton
									height="12"
									width="35%"
									border-radius="m"
									style="margin-bottom: 5px"
								/>
							</div>
						</div>
						<div
							v-if="!loading"
							class="price"
						>
							<div
								class="tab-price"
								v-if="isOnSale"
							>
								<TnParagraph
									bold
									size="xs"
									responsive
									class="tab-price-prefix"
								>
									{{ currentPricePrefix }}
								</TnParagraph>
								<TnHeading
									bold
									size="m"
									responsive
								>
									{{ tab.price }}
								</TnHeading>

								<TnParagraph
									bold
									size="xs"
									responsive
									>{{ tab?.suffix }}</TnParagraph
								>
							</div>
							<div
								class="tab-price"
								v-if="tab?.price && !isOnSale"
							>
								<TnParagraph
									bold
									size="xs"
									responsive
									class="tab-price-prefix"
								>
									{{ currentPricePrefix }}
								</TnParagraph>
								<TnHeading
									bold
									style="margin-bottom: -5px"
									size="m"
									responsive
								>
									{{ tab.price }}
								</TnHeading>

								<TnParagraph
									bold
									size="xs"
									responsive
									>{{ tab?.suffix }}</TnParagraph
								>
							</div>
							<div
								class="tab-price"
								v-show="tab?.price && isOnSale"
							>
								<TnParagraph
									class="strike-through"
									size="xs"
									responsive
									>Før: {{ tab?.basePrice }} {{ tab?.suffix }}</TnParagraph
								>
							</div>

							<TnParagraph
								v-if="tab.additionalInfo && !hideAdditional && !onlyFullPriceAvailableDisclaimer"
								class="disclaimer-text"
								size="xs"
							>
								{{ tab.additionalInfo }}
							</TnParagraph>
						</div>
					</div>
					<TnParagraph
						v-if="onlyFullPriceAvailableDisclaimer && !loading"
						class="full-price-disclaimer-text disclaimer-text"
						size="xs"
					>
						{{ onlyFullPriceAvailableDisclaimer }}
					</TnParagraph>
				</div>
				<!-- Price -->
			</div>

			<!-- Storage availability -->
			<TnSkeleton
				height="20px"
				v-if="loading"
				width="80%"
				border-radius="m"
			/>

			<MainStock
				v-if="!loading && !buttonVariant"
				:main-stock="mainStock"
				:size="isMobile ? '12px' : '14px'"
				:unVerifiedDeliveryText="unVerifiedDeliveryText"
				:thresholds="thresholds"
				class="stock"
				@isUnavailable="setUnavailable"
				grid
				:product="selectedProduct"
			/>
			<!-- Storage availability -->
		</TnCard>
	</LinkWrap>
</template>
<script>
/*
  THIS IS THE NEW PRODUCT-CARD AS OF 10.02.2025
  ./ProductCardWrapper.vue will be deleted when super secret project is out
*/
import ResponsiveImageViewModel from "~/helpers/ViewModels/ResponsiveImageViewModel";
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel";
import IsTestMixin from "~/mixins/platform/IsTestMixin.js";
import striptags from "striptags";
import { mapState } from "vuex";
import { formatPrice } from "~/helpers/formatting/price";

const asSafeString = (str) => {
	if (!str) return "";
	return str
		.toLowerCase()
		.replace(/å/g, "a")
		.replace(/æ/g, "a")
		.replace(/ø/g, "o")
		.replace(/\+/g, "plus")
		.replace(/[^\w\s]/g, "")
		.replace(/\s/g, "-");
};

export default defineNuxtComponent({
	props: {
		badgeCategory: {
			type: String,
		},
		selectedPaymentOption: {
			type: Number,
		},
		unVerifiedDeliveryText: {
			type: String,
		},
		product: {
			type: Object,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		hideVariant: {
			type: Boolean,
		},
		hideAdditional: {
			type: Boolean,
		},
		defaultTabIndex: {
			type: Number,
			default: 0,
		},
		noLink: {
			// Used when you don't want to use productLink but handle the click event some other way
			type: Boolean,
		},
		swapSubscriptionDisclaimerText: {
			type: String,
		},
	},

	mixins: [IsTestMixin],

	data() {
		return {
			tabIndex: 0,
			currentVariant: this.product.productId,
			variantsPreloaded: false,
		};
	},

	beforeMount() {
		this.tabIndex = this.selectedPaymentOption || this.defaultTabIndex;
	},

	mounted() {
		if (this.productVariants?.length > 0 && !this.hideVariant) {
			this.currentVariant = this.productVariants[0].id;
		}
	},

	watch: {
		selectedPaymentOption: {
			handler(value) {
				this.tabIndex = value;
			},
			immediate: true,
		},
	},

	computed: {
		...mapState("ecommerce", ["categoriesThatRequireStock"]),
		onlyFullPriceAvailableDisclaimer() {
			if (this.selectedPaymentOption === 1 && !this.tabs?.[1]?.price) {
				return "Kun fullpris tilgjengelig";
			} else return false;
		},
		mainStock() {
			return "Auto";
		},
		isMobile() {
			const mobileBreakpoint = 768;
			return window?.innerWidth < mobileBreakpoint;
		},
		thresholds() {
			return [];
		},
		title() {
			const categories = ["Nettbrett", "Smartklokker"];
			if (categories.includes(this.product.category && this.model !== "Model")) {
				return this.product.productName.replace(new RegExp([this.brand, this.model].join("|"), "gi"), "").trim();
			}
			return this.product.productName.replace(new RegExp(this.brand, "i"), "").trim();
		},
		model() {
			return this.product?.model ?? "Model";
		},
		brand() {
			if (this.product?.brand) {
				return this.product?.brand;
			}
			return false;
		},
		id() {
			return this.product.productId;
		},
		label() {
			return this.product?.memberPrice?.campaignData?.badgeText
				? this.product.memberPrice.campaignData.badgeText
				: this.product.productLabel;
		},
		imageHeight() {
			if (this.product.class === "xl") return 359 * 2;
			return 175 * 2;
		},
		image() {
			return (
				this.product.productImage?.image_md?.src &&
				ResponsiveImageViewModel(this.product.productImage, undefined, this.imageHeight)
			);
		},
		description() {
			return this.product.productDescription && striptags(this.product.productDescription.trim().split("\n")[0]);
		},
		additionalInfo() {
			return this.product.productAdditionalInfo;
		},
		extraAdditionalInfo() {
			return this.swapSubscriptionDisclaimerText || this.product.productExtraAdditionalInfo;
		},
		variants() {
			return this.product.variants || [];
		},
		safeCurrentVariant() {
			if (this.currentVariant) return this.currentVariant;
			if (this.product.variants?.length > 0) {
				if (!this.product.variants.some((v) => v.id === this.product.productId)) return this.product.variants[0].id;
			}
			return undefined;
		},
		tabTexts() {
			if (!Array.isArray(this.tabs)) return null;

			const tabTexts = this.tabs
				.map((tab) => {
					return tab.text;
				})
				.filter((tabText) => tabText);

			// Only return tab texts if there are more than 1 tab
			return tabTexts.length > 1 ? tabTexts : null;
		},
		link() {
			if (this.noLink) return { href: "javascript:" };
			if (this.product.productLink) {
				const urlSegments = [
					this.product.category,
					this.product.brand || this.selectedProduct.brand,
					this.selectedProduct.title || this.selectedProduct.rawProductName,
					this.selectedProduct.condition || this.product.condition,
				]
					.map(asSafeString)
					.filter((s) => s);

				let url = `/${urlSegments.join("/")}/`;
				if (this.$store.getters["ecommerce/segment"] === "business") url = "/bedrift" + url;

				return { to: url };
			}
			return { href: "javascript:" };
		},
		swapIsSelected() {
			return this.tabTexts && this.tabTexts[this.tabIndex]?.startsWith("SWAP");
		},
		prices() {
			return this.product?.prices;
		},
		tabs() {
			return [
				{
					type: "fullPrice",
					text: "Betal nå",
					price: formatPrice.oneTime(this.prices.gridSortPrices?.fullPrice?.value, { ceil: true }),
					basePrice: formatPrice.oneTime(this.prices?.gridSortPrices?.basePrice, { ceil: true }),
					hasPriceRange: this.prices.gridSortPrices?.fullPrice?.hasPriceRange,
				},
				{
					type: "monthly",
					text: "Nedbetaling",
					price: formatPrice.oneTime(this.prices.gridSortPrices?.monthly?.value, { ceil: true }),
					basePrice: formatPrice.oneTime(this.prices?.gridSortPrices?.basePrice / 36, { ceil: true }),
					hasPriceRange: this.prices.gridSortPrices?.monthly?.hasPriceRange,
					additionalInfo: `Totalt etter 36 md. ${formatPrice.oneTime(this.prices.gridSortPrices?.fullPrice.value, { ceil: true })}`,
					suffix: " /md.",
				},
			];
		},
		isOnSale() {
			const selectedPrice = this.tabs[this.tabIndex];
			return selectedPrice?.price !== selectedPrice?.basePrice;
		},
		currentPricePrefix() {
			return this.tabs[this.tabIndex]?.hasPriceRange ? "Fra: " : this.isOnSale ? "Nå: " : "";
		},
		selectedProduct() {
			return {
				...this.product,
				productId: this.safeCurrentVariant || this.product.productId,
				title: this.variants.find((variant) => variant.id === this.safeCurrentVariant)?.title,
				brand: this.variants.find((variant) => variant.id === this.safeCurrentVariant)?.brand,
				condition: this.variants.find((variant) => variant.id === this.safeCurrentVariant)?.condition,
				inventory:
					this.variants.length > 0
						? this.variants.find((variant) => variant.id === this.safeCurrentVariant)?.inventory
						: this.product.inventory,
			};
		},
		productVariants() {
			if (this.variants.length > 1) {
				const variants = this.variants.map((variant) => {
					return {
						...variant,
						image: variant.image && ResponsiveImageViewModel(variant.image, undefined, this.imageHeight),
						link: variant.link && LinkViewModel(variant.link),
					};
				});

				const sortedVariants = [variants.find((v) => v.id === this.id), ...variants.filter((v) => v.id !== this.id)]
					.filter((v) => v)
					.sort((a, b) => b.inventory?.[0]?.amount - a.inventory?.[0]?.amount);

				let uniqueVariants = [];
				for (const item of sortedVariants) {
					const isDuplicate = uniqueVariants.find((obj) => obj.colorCode === item.colorCode);
					if (!isDuplicate) {
						uniqueVariants.push(item);
					}
				}

				uniqueVariants = uniqueVariants.sort((a, b) => a?.priority - b?.priority);

				if (this.categoriesThatRequireStock?.includes(this.product?.category)) {
					const sortedUniqueVariantsWithInventory = uniqueVariants
						.filter((v) => v.inventory?.[0]?.amount > 0)
						.sort((a, b) => a.priority - b.priority);
					const sortedUniqueVariantsWithoutInventory = uniqueVariants
						.filter((v) => v.inventory?.[0]?.amount === 0 || !v.inventory)
						.sort((a, b) => a.priority - b.priority);
					uniqueVariants = [...sortedUniqueVariantsWithInventory, ...sortedUniqueVariantsWithoutInventory];
				}

				return uniqueVariants.length > 3 ? uniqueVariants.slice(0, 3) : uniqueVariants;
			} else return [];
		},
		moreVariants() {
			if (this.variants.length > 1) {
				const variants = this.variants.map((variant) => {
					return {
						...variant,
						image: variant.image && ResponsiveImageViewModel(variant.image, undefined, this.imageHeight),
						link: variant.link && LinkViewModel(variant.link),
					};
				});

				const sortedVariants = [variants.find((v) => v.id === this.id), ...variants.filter((v) => v.id !== this.id)]
					.filter((v) => v)
					.sort((a, b) => b.inventory?.[0]?.amount - a.inventory?.[0]?.amount);

				let uniqueVariants = [];
				for (const item of sortedVariants) {
					const isDuplicate = uniqueVariants.find((obj) => obj.colorCode === item.colorCode);
					if (!isDuplicate) {
						uniqueVariants.push(item);
					}
				}

				uniqueVariants = uniqueVariants.sort((a, b) => a?.priority - b?.priority);

				return uniqueVariants.length - this.productVariants.length;
			} else return 0;
		},
		useModelNameCategory() {
			return this.product.category === "Tilbehør" || this.product.category === "Smartklokker";
		},
	},
	methods: {
		cleanPrefix(prefix) {
			return prefix?.toString()?.replace(/:/g, "");
		},
		setUnavailable(value) {
			this.isUnavailable = value;
		},
		selectTab(event) {
			this.tabIndex = event.index;
		},
		activeTab(index) {
			if (this.tabs?.length === 1) {
				return true;
			}
			return index === this.tabIndex;
		},
		changeVariant(variant) {
			this.currentVariant = variant.id;
		},
		preloadVariants() {
			this.variantsPreloaded = true;
		},
	},
});
</script>

<style lang="scss" scoped>
.card {
	max-width: 302px;
	height: 530px;
	display: grid !important;
	grid-template-rows: 261px auto 30px;
	flex-direction: column;
	padding: $spacing-3xl $spacing-m $spacing-m $spacing-m;
	box-shadow:
		0 4px 12px 0 rgba(0, 0, 0, 0.04),
		0 2px 8px 0 rgba(0, 0, 0, 0.08),
		0 0 4px -2px rgba(0, 0, 0, 0.12);
	&:hover {
		margin-top: -20px;
		transition: all 0.3s ease;
		box-shadow:
			0 8px 16px 0 rgba(0, 0, 0, 0.04),
			0 2px 8px 0 rgba(0, 0, 0, 0.08),
			0 0 4px -2px rgba(0, 0, 0, 0.12);
	}
	&:not(:hover) {
		transition: all 0.3s ease;
	}

	.badge-container {
		z-index: 1;

		// Text-size from TnBadge not working correctly
		@include breakpoint(mobile) {
			font-size: 12px;
		}
	}

	.image-container {
		width: 100%;
		height: 261px;
		position: relative;
		object-fit: contain;
		display: grid;
		place-items: center;

		img {
			width: 100%;
			height: 200px;
			object-fit: contain;
			transition: all 0.1s ease-in-out;

			@include breakpoint(mobile) {
				width: 100%;
				height: 100px;
			}
		}

		@include breakpoint(mobile) {
			width: 100%;
			height: 100px;
		}
	}

	&-content {
		display: grid;
		grid-template-rows: calc(74px + $spacing-l) auto;

		@include breakpoint(mobile) {
			grid-template-rows: calc(86px + $spacing-s) auto;
		}
	}

	.heading {
		display: grid;
		grid-template-areas:
			"brand color"
			"title title";
		grid-template-rows: 22px 52px auto;
		margin-top: $spacing-l;

		.variant-selector {
			display: flex;
			gap: 2px;
			transform: translate(3px, 2px);

			.variant {
				width: 18px;
				height: 18px;
				display: flex;
				border-radius: 50%;
				border: 2px solid white;
				box-shadow: 0 2px 3px 0 #0003 inset;
				transition: transform 0.3s ease;

				&.active:not(.more) {
					outline: 2px solid $color-cta-active;
				}

				&.more {
					width: auto;
					border-radius: 0;
					box-shadow: none;
					position: relative;
					top: -1px;

					@include font-text-s;
				}

				@include breakpoint(mobile) {
					width: 14px;
					height: 14px;
				}
			}
		}

		.title-variant-wrapper {
			grid-area: color;
			display: flex;
			align-items: center;
			align-self: center;
			justify-content: flex-end;
			height: 20px;

			@include breakpoint(mobile) {
				height: 18px;
			}

			@include breakpoint(mobile) {
				justify-content: flex-start;
			}
		}

		.product {
			&-brand {
				white-space: nowrap;
				overflow: hidden;
				grid-area: brand;
				align-self: center;

				@include font-title-2xs;
			}

			&-title {
				max-height: 52px;
				height: max-content;
				overflow: hidden;
				grid-area: title;

				@include font-title-bold-xs;

				@include breakpoint(mobile) {
					height: 32px;
				}
			}

			&-model {
				white-space: nowrap;
				overflow: hidden;
				grid-area: model;

				@include font-title-2xs;
			}

			@include breakpoint(mobile) {
				&-brand {
					@include font-text-2xs;
				}

				&-title {
					@include font-text-bold-2xs;
				}

				&-model {
					grid-area: color;

					@include font-text-2xs;
				}
			}
		}

		@include breakpoint(mobile) {
			grid-template-rows: 16px 32px auto;
			grid-template-areas:
				"brand brand"
				"title title"
				"color color";
		}

		@include breakpoint(mobile) {
			margin-top: $spacing-m;
		}
	}

	.price {
		.tab-price {
			color: $color-primary-mid;
			display: flex;
			align-items: baseline;

			&-prefix {
				margin-right: $spacing-xs;
			}

			p:not(.strike-through) {
				color: $color-primary-mid;
			}
		}

		.disclaimer-text-extra {
			font-size: 14px !important;
			color: $color-neutrals-600-shade;

			@include breakpoint(mobile) {
				font-size: 12px !important;
			}
		}

		.disclaimer-text {
			font-size: 14px !important;
			color: $color-neutrals-600-shade;

			@include breakpoint(mobile) {
				font-size: 12px !important;
			}
		}

		.strike-through {
			color: $color-neutrals-600-shade;
			text-decoration: line-through;
		}
	}

	.stock {
		font-size: 14px !important;

		@include breakpoint(mobile) {
			font-size: 12px !important;
		}
	}

	@include breakpoint(mobile) {
		grid-template-rows: 100px auto 36px;
	}

	@include breakpoint(mobile) {
		padding: $spacing-2xl $spacing-s $spacing-s $spacing-s;
	}

	@include breakpoint(mobile) {
		height: 340px;

		.title-variant-wrapper {
			display: block;
		}

		.heading {
			.brand-color {
				display: flex;
				margin-bottom: $spacing-xs;
				align-items: center;
			}

			p {
				text-wrap: wrap;
			}
		}

		.price {
			font-weight: bold;
		}
	}
}

.price-container {
	position: relative;

	.full-price-disclaimer-text {
		position: absolute;
		bottom: $spacing-xs;
		margin-left: 6px;
		color: $color-primary-mid !important;
	}
}

.disclaimer-text {
	font-size: 14px !important;
	color: $color-neutrals-600-shade;

	@include breakpoint(mobile) {
		font-size: 12px !important;
	}
}

a {
	width: 280px;

	* {
		max-width: calc(280px - calc($spacing-m * 2));

		@include breakpoint(mobile) {
			max-width: calc(165px - calc($spacing-s * 2));
		}
	}

	@include breakpoint(mobile) {
		width: 165px;
	}
}
</style>
